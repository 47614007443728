import React, { useState } from "react"

// Components
import Seo from "../../../../components/seo"
import SidebarLayout from "../../../../layouts/SidebarLayout"
import Section from "../../../../components/section/Section"
import ContentNavigation from "../../../../components/scrollspy"

// SEO Image
import ogImage from "../../../../images/seo/search-inputs/search-inputs.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../../components/preview"

import FormElementsAllSearchPlainPreview from "../../../../library/components/form-elements/search/react/_preview/plain"
import FormElementsAllSearchRoundedPreview from "../../../../library/components/form-elements/search/react/_preview/rounded"

import HeroSearchInputComponent from "../../../../library/components/form-elements/search/react/_preview/HeroSearchInputComponent"

// Plain Input Fields

// Base
const FormElementsSearchPlainBaseBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/search/react/plain/base/basic.jsx")
const FormElementsSearchPlainBaseBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/search/html/plain/base/basic.html")

// Lg
const FormElementsSearchPlainLgBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/search/react/plain/lg/basic.jsx")
const FormElementsSearchPlainLgBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/search/html/plain/lg/basic.html")

// Rounded Input Fields
// Base
const FormElementsSearchRoundedBaseBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/search/react/rounded/base/basic.jsx")
const FormElementsSearchRoundedBaseBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/search/html/rounded/base/basic.html")

// Lg
const FormElementsSearchRoundedLgBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/search/react/rounded/lg/basic.jsx")
const FormElementsSearchRoundedLgBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/search/html/rounded/lg/basic.html")

export default function FormElementSearchInputsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "FormElementsSearchInput",
      title: "Basic search input",
      active_tab: 1,
    },
    {
      component_name: "FormElementsSearchInputRounded",
      title: "Outline",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Search Inputs - WindUI Component Library"
        ogtitle="Tailwind CSS Search Inputs - WindUI Component Library"
        description="Search inputs, allow users to enter search queries and receive relevant results. Built with Tailwind CSS by WindUI."
        ogdescription="Search inputs, allow users to enter search queries and receive relevant results. Built with Tailwind CSS by WindUI."
        url="components/form-elements/search-inputs/"
        author="Wind UI"
        ogimage={ogImage}
        keywords="Tailwind CSS, Tailwind CSS Search Inputs, Search Inputs, Search Input Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Search Inputs</h1>
          <p>
            Allow users to enter search queries and receive relevant results
            with the search input field
          </p>

          <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <HeroSearchInputComponent />
            </section>
          </div>
        </Section>
        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Input Basic Section */}
          <h3 id="FormElementsSearchInput">Basic Search Input</h3>

          <PreviewBlock
            id="FormElementsSearchInput"
            HtmlComponent={FormElementsSearchPlainBaseBasicHTML.default}
            JsxComponent={FormElementsSearchPlainBaseBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <FormElementsAllSearchPlainPreview
                FormElementsSearchPlainBaseBasic={
                  activeTabs[0].active_tab === 1
                    ? FormElementsSearchPlainBaseBasicHTML.default
                    : FormElementsSearchPlainBaseBasicJsx.default
                }
                FormElementsSearchPlainLgBasic={
                  activeTabs[0].active_tab === 1
                    ? FormElementsSearchPlainLgBasicHTML.default
                    : FormElementsSearchPlainLgBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Input Outlined Section */}
          <h3 id="FormElementsSearchInputRounded">Outline Search Input</h3>

          <PreviewBlock
            id="FormElementsSearchInputRounded"
            HtmlComponent={FormElementsSearchRoundedBaseBasicHTML.default}
            JsxComponent={FormElementsSearchRoundedBaseBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <FormElementsAllSearchRoundedPreview
                FormElementsSearchRoundedBaseBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsSearchRoundedBaseBasicHTML.default
                    : FormElementsSearchRoundedBaseBasicJsx.default
                }
                FormElementsSearchRoundedLgBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsSearchRoundedLgBasicHTML.default
                    : FormElementsSearchRoundedLgBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>
        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            <code>&lt;input&gt;</code> elements of type <code>search</code> are
            very similar to those of type text, except that they are
            specifically intended for handling search terms.{" "}
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Search input fields come in two basic styles (<strong>base</strong>{" "}
            and <strong>outlined</strong>) and 2 sizes:
          </p>
          <ul>
            <li>
              <strong>Base:</strong> text font size of 0.875rem (14px).
            </li>
            <li>
              <strong>Large:</strong> text font size of 1rem (16px).
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            For better accessibility when using the <code>&lt;input&gt;</code>{" "}
            type <code>search</code> element:
          </p>
          <ul>
            <li>
              Use an <code>aria-label</code> attribute on the{" "}
              <code>&lt;input&gt;</code> itself. This should replace the missing
              label that will be read out as an equivalent by the screen reader.
            </li>
            <li>
              A <code>role</code> attribute of value <code>search</code> on the{" "}
              <code>&lt;form&gt;</code> element will cause screen readers to
              announce that the form is a search form.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
